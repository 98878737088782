import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "memory"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/memory.png"}) { ...eyecatchImg },
    memory_s: file(relativePath: { eq: "memory/memory.png"}) { ...smallImg },
    memory_sikumi: file(relativePath: { eq: "memory/memory_sikumi.png"}) { ...normalImg },
    dualchannel: file(relativePath: { eq: "memory/dualchannel.png"}) { ...normalImg },
    memoryexpansion_s: file(relativePath: { eq: "memory/memory-expansion.png"}) { ...smallImg },
    ddr: file(relativePath: { eq: "memory/ddr.png"}) { ...normalImg },
    task16gb: file(relativePath: { eq: "memory/task16gb.png"}) { ...normalImg },
    memoryslot_s: file(relativePath: { eq: "memory/memory-slot.png"}) { ...smallImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【8GB,16GB,32GB】メモリ容量のおすすめは何か？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="メモリの選び方" mdxType="Image" />
    <p>{`パソコンの速度に大きな影響を与えるメモリ、4GB、8GB、16GB、32GB等メモリ容量を決定することが最も重要であるが、他にメモリ自体の速度、デュアルチャネル化など考慮すべき点はある。`}</p>
    <p>{`本ページではメモリ関連の用語を抑え、自分に適したメモリを選択できるようになることを目的とする。予算に応じて賢い買い物を行なおう。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "メモリとは？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%A8%E3%81%AF%EF%BC%9F",
        "aria-label": "メモリとは？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリとは？`}</h2>
    <Image {...props} name="memory_s" alt="メモリ" mdxType="Image" />
    <p>{`メモリとは広い意味ではパソコンの内容を記憶する装置のことを呼び、USBメモリのような電源を切ってもデータを保持するもの（補助記憶装置ともよぶ)もメモリの一種である。`}</p>
    <p>{`しかし、パソコンで単にメモリと言えば写真のようなメモリモジュール(別名SDRAM、DRAM)のことであり、`}<strong parentName="p"><em parentName="strong">{`OSやアプリケーションを実行させるために必要な領域を提供する`}</em></strong>{`役割を果たす。電源を切ると読み込んだ内容が消去され、再度電源を入れると読み込み直される。`}</p>
    <p>{`メモリが使用されるイメージを図示すると下記のようになる。`}</p>
    <Image {...props} name="memory_sikumi" alt="メモリの仕組み" mdxType="Image" />
    <p>{`アプリケーションを起動するとまず、メモリにアプリケーションデータがロードされる。`}</p>
    <p>{`メモリ領域をより効率的に使うために、あまり使われないデータはSSDなどのストレージに一時的に格納され、必要に応じて取り出される。`}</p>
    <p>{`ストレージをメモリとして使うことで、見せかけのメモリ量を大きくすることを`}<em parentName="p">{`仮想メモリ方式`}</em>{`と専門用語では呼んでいる。`}</p>
    <p>{`アプリケーションを複数起動させ、メモリの量が不足してくると、`}<strong parentName="p"><em parentName="strong">{`メモリと比べ低速なストレージにアクセスする回数が増え`}</em>{`、アプリケーションの動作が重くなる、あるいは運が悪いと強制終了したりする`}</strong>{`のである。`}</p>
    <p>{`メモリは作業机の広さに例えられることがあるが、ストレージは棚である。`}<strong parentName="p">{`机が狭いと書類を棚に出し入れする回数が増え、作業効率が落ちるのと同じような話`}</strong>{`である。`}</p>
    <h2 {...{
      "id": "メモリ容量は大抵8GBで事足りる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E5%AE%B9%E9%87%8F%E3%81%AF%E5%A4%A7%E6%8A%B58GB%E3%81%A7%E4%BA%8B%E8%B6%B3%E3%82%8A%E3%82%8B",
        "aria-label": "メモリ容量は大抵8GBで事足りる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリ容量は大抵8GBで事足りる`}</h2>
    <p>{`さて、メモリが不足するとアプリケーションが重くなることは説明したが、どれぐらいのメモリ容量がおすすめなのだろうか？`}<strong parentName="p">{`実際問題としては`}<em parentName="strong">{`4GB`}</em>{`、`}<em parentName="strong">{`8GB`}</em>{`、`}<em parentName="strong">{`16GB`}</em>{`、`}<em parentName="strong">{`32GB`}</em>{`で悩むことになるはず`}</strong>{`である。`}</p>
    <p>{`まず、ネットサーフィンと動画閲覧、メールが中心でブラウザ以外のソフトはほとんど起動することがないならば4GBで全く問題がない。`}</p>
    <p>{`しかし同時に音楽を再生する、ブラウザでも複数のタブを同時に開く(10個程度)、ウイルススキャンを裏で走らせるなど複数作業を同時にこなすとなると、4GBでは動作が全体的にもっさりとしてしまい、8GBのメモリが必要となってくる。`}</p>
    <p><strong parentName="p">{`8GBあれば大抵の動画編集や3Dゲームも実行することができ、`}<em parentName="strong">{`特殊な場合を除いてパソコンで行う作業のほとんど全てを快適に行うことができる`}</em></strong>{`だろう。`}</p>
    <h2 {...{
      "id": "16GBのメモリがあった方が良いケース",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#16GB%E3%81%AE%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%8C%E3%81%82%E3%81%A3%E3%81%9F%E6%96%B9%E3%81%8C%E8%89%AF%E3%81%84%E3%82%B1%E3%83%BC%E3%82%B9",
        "aria-label": "16GBのメモリがあった方が良いケース permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`16GBのメモリがあった方が良いケース`}</h2>
    <p>{`4GBから8GBにメモリを増やすときは8GBから16GBへ増やすに比べてコストは半分で済み、また効果も実感しやすい。しかし16GBあった方が良いケースもあるので例を挙げていく。`}</p>
    <h3 {...{
      "id": "最新の3DゲームやVRをする場合",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%80%E6%96%B0%E3%81%AE3D%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%84VR%E3%82%92%E3%81%99%E3%82%8B%E5%A0%B4%E5%90%88",
        "aria-label": "最新の3DゲームやVRをする場合 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`最新の3DゲームやVRをする場合`}</h3>
    <p>{`ゲームにはどの程度のスペックがあれば快適に遊べるかを各メーカーが`}<em parentName="p">{`推奨スペック`}</em>{`という形で提示している。`}</p>
    <p>{`軽めの3Dゲームは8GBで問題がないものの、日本のゲームでも例えば`}<strong parentName="p"><em parentName="strong">{`モンハンワイルズが最低スペックとして16GBを要求する(推奨も16GB)`}</em></strong>{`など、16GBはゲームにとって標準装備となってきている。`}</p>
    <p>{`メモリ不足でカクツキが発生すると勝てるゲームも勝てなくなるので、ゲームを快適に行いたい場合は16GBを積んでおくのが良いだろう。`}</p>
    <h3 {...{
      "id": "プロが使用するような本格的な動画編集ソフトを使用したい場合",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%97%E3%83%AD%E3%81%8C%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E3%82%88%E3%81%86%E3%81%AA%E6%9C%AC%E6%A0%BC%E7%9A%84%E3%81%AA%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E3%82%BD%E3%83%95%E3%83%88%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%81%84%E5%A0%B4%E5%90%88",
        "aria-label": "プロが使用するような本格的な動画編集ソフトを使用したい場合 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`プロが使用するような本格的な動画編集ソフトを使用したい場合`}</h3>
    <p>{`例えばAdobe After EffectsやAdobe Premiere Proというソフトは16GB以上のメモリを積むことを推奨している。推奨スペックが動画編集ソフトには書かれているはずなので、その基準に沿うようにしよう。`}</p>
    <p>{`プロが使うような本格的な動画編集ソフトを使いたい人は最低でも16GBで、できれば32GBは搭載しておきたい。`}</p>
    <SmallBox type="link" mdxType="SmallBox">
  <Link to="/editvideo/" mdxType="Link">本格的な動画編集を行いたい人へ向けたパソコンの選び方</Link>
    </SmallBox>
    <h3 {...{
      "id": "同時にアプリケーションを大量に立ち上げたい場合",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%90%8C%E6%99%82%E3%81%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92%E5%A4%A7%E9%87%8F%E3%81%AB%E7%AB%8B%E3%81%A1%E4%B8%8A%E3%81%92%E3%81%9F%E3%81%84%E5%A0%B4%E5%90%88",
        "aria-label": "同時にアプリケーションを大量に立ち上げたい場合 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`同時にアプリケーションを大量に立ち上げたい場合`}</h3>
    <p>{`ブラウザのタブを40個開き、バックグラウンドで音楽を流し、ウイルススキャンを裏で走らせ...などして敢えてメモリを多く使用すると16GBの恩恵がギリギリ得られるかもしれない。Google Chromeはあればあるだけメモリを使い、メモリが余っている人には最適なブラウザである。`}</p>
    <h3 {...{
      "id": "仮想デスクトップ環境を構築したい場合",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BB%AE%E6%83%B3%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E7%92%B0%E5%A2%83%E3%82%92%E6%A7%8B%E7%AF%89%E3%81%97%E3%81%9F%E3%81%84%E5%A0%B4%E5%90%88",
        "aria-label": "仮想デスクトップ環境を構築したい場合 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`仮想デスクトップ環境を構築したい場合`}</h3>
    <p>{`プログラミングを勉強するために、Windowsの中にLinuxを入れて動かしたいという場合、メモリを仮想デスクトップ用にいくらか確保しなければならず、その分普段使いのメモリが圧迫される。`}</p>
    <p>{`同様にAndroidやiOS開発でシュミレーターを起動するといった場合もメモリを多く消費する。プログラマならばメモリ16GBは確保しておきたい。`}</p>
    <h2 {...{
      "id": "32GBは主に高度な動画編集用途",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#32GB%E3%81%AF%E4%B8%BB%E3%81%AB%E9%AB%98%E5%BA%A6%E3%81%AA%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E7%94%A8%E9%80%94",
        "aria-label": "32GBは主に高度な動画編集用途 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`32GBは主に高度な動画編集用途`}</h2>
    <p>{`一般的な用途で32GBが必要となるケースはほとんどないが、動画編集では多くのメモリを使用する機会がある。`}</p>
    <p>{`例えばAdobeのAfter Effectsというプロ向け動画編集ソフトを使用している時に、作成中動画の出来栄えをチェックするためにプレビュー再生を行なうことがある。`}</p>
    <p>{`この時にメモリが32GBあると16GBと比べて長時間再生することができ、トライアンドエラーにかかるワークフローの負荷を減らすことができる。`}</p>
    <h2 {...{
      "id": "4GB、8GB、16GB、32GBを用途別に表にまとめる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4GB%E3%80%818GB%E3%80%8116GB%E3%80%8132GB%E3%82%92%E7%94%A8%E9%80%94%E5%88%A5%E3%81%AB%E8%A1%A8%E3%81%AB%E3%81%BE%E3%81%A8%E3%82%81%E3%82%8B",
        "aria-label": "4GB、8GB、16GB、32GBを用途別に表にまとめる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4GB、8GB、16GB、32GBを用途別に表にまとめる`}</h2>
    <p>{`最後におすすめのメモリ容量を表としてまとめてみた。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th className="no-wrap">メモリ</th>
      <th>用途</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>4GB</td>
      <td>軽い事務作業、ブラウザのタブ４つ程度</td>
    </tr>
    <tr>
      <td>8GB</td>
      <td>ブラウザのタブ大量、そこそこのマルチタスク、FullHDまでの映像編集、軽めの3Dゲーム</td>
    </tr>
    <tr>
      <td>16GB</td>
      <td>超マルチタスク、ハードなビジネス用途、4Kでのゲームプレイ、重めの3Dゲーム、Adobe After Effects、Adobe Premiere Proでの本格映像編集、仮想デスクトップ、Androidシュミレータ</td>
    </tr>
    <tr>
      <td>32GB</td>
      <td>4K動画編集、3Dモデリングの長時間プレビュー再生、本格的なAIの学習</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`軽い作業は4GB、ある程度まじめな事務用途は8GB、ゲームは16GB、プロのクリエイターは32GBあれば安心である。`}</p>
    <h2 {...{
      "id": "8GBの容量で十分なのに16GBにする理由",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8GB%E3%81%AE%E5%AE%B9%E9%87%8F%E3%81%A7%E5%8D%81%E5%88%86%E3%81%AA%E3%81%AE%E3%81%AB16GB%E3%81%AB%E3%81%99%E3%82%8B%E7%90%86%E7%94%B1",
        "aria-label": "8GBの容量で十分なのに16GBにする理由 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8GBの容量で十分なのに16GBにする理由`}</h2>
    <p>{`容量としては8GBで十分であっても、特定のタスクでは8GBのメモリを2枚使い16GBにした方が理由はあり、それが`}<em parentName="p">{`デュアルチャネル`}</em>{`による高速化である。`}</p>
    <Image {...props} name="dualchannel" alt="デュアルチャネル" mdxType="Image" />
    <p>{`デュアルチャネルはメモリとCPUの間の伝送路の道幅(`}<em parentName="p">{`帯域幅`}</em>{`)を理論上２倍(実質は1.5倍程度)に広げることで、効率的にデータの受け渡しを行う仕組みである。`}</p>
    <p>{`これにより`}<strong parentName="p">{`CPUとメモリが大量のデータのやり取りを頻繁に行うファイルの解凍や動画のエンコード、複雑な3Dシュミレーションなどの処理が高速化される`}</strong>{`。`}</p>
    <p>{`ストレージとメモリ間の帯域幅を広くするわけではないので、ファイルのコピーやゲームデータのロード等が高速化されるわけではないことは注意が必要である。`}</p>
    <p><em parentName="p">{`同じサイズのメモリ２枚がデュアルチャネル化の条件`}</em>{`であるため、8GBのメモリ2枚と同様、4GBのメモリ2枚でも問題がない。ただしパソコン購入時に4GBx2は選択肢として存在しないため、8GBx2が主流となっている。`}</p>
    <p>{`高速化される処理と、程度はおおよそ下記の通り。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th className="no-wrap">処理内容</th>
      <th>改善内容</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>ファイルの圧縮</td>
      <td>デュアルチャネルで高速化される代表的なタスク。メモリに読み込んだデータをCPUと相互にやり取りを頻繁に行い計算して圧縮していく。20%程度は高速化が可能。</td>
    </tr>
    <tr>
      <td>動画のエンコード</td>
      <td>動画形式の変換はファイルの圧縮と同様、大きなデータをCPUとメモリがやり取りをして計算処理をこなしていく。エンコードする形式にもよるが10%程度高速化が可能。</td>
    </tr>
    <tr>
      <td>3Dゲーム(グラボ搭載無)</td>
      <td>グラボを搭載しない場合には、グラボ内のビデオメモリ(VRAM)の代わりにメモリが使われる。物理演算をリアルタイムで行う必要があるのでメモリとCPUのやり取りが多い。15%程度フレームレートが向上し映像が滑らかになる。</td>
    </tr>
    <tr>
      <td>3Dゲーム(ハイエンドグラボ搭載)</td>
      <td>物理演算はグラボ内のVRAMが行うが、その他のリアルタイム計算処理も多く、メモリが圧迫されることがある。ゲームによりけりだが、10%程度フレームレートが向上する。</td>
    </tr>
    <tr>
      <td>AI学習</td>
      <td>AI学習では大量の行列データを計算するため、メモリとCPUのやり取りが多くなる。計算内容にもよるが10%程度は処理速度が向上</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`一方でアプリケーションやOSの起動や、ゲームのロードは主にストレージの速度に依存するため、理論的には多少高速化はされるが体感できるほどにはならない。`}</p>
    <p>{`総括すると、`}<strong parentName="p">{`Youtuberや動画クリエイターのように動画の形式変換をよく行う人、ゲーミングPCを買うまでは行かないが、3Dゲームを遊んでみたい人、AI学習や物理演算を実験などで行う必要がある人はデュアルチャネルの恩恵が受けられる`}</strong>{`。`}</p>
    <p>{`10万以上するハイエンドなグラフィックボードを積む人は、言われなくてもデュアルチャネルにするだろうから取り敢えず無視。`}</p>
    <p>{`他の一般の人は、滅多にしないファイルの圧縮で10秒が8秒になったぐらいのレベルであり、`}<strong parentName="p"><em parentName="strong">{`メモリの容量と比べると恩恵が遥かに少ない`}</em>{`ため、あまり効果は体感できない`}</strong>{`であろう。`}</p>
    <h2 {...{
      "id": "8GBか16GBで迷ったら増設できるかで決める",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8GB%E3%81%8B16GB%E3%81%A7%E8%BF%B7%E3%81%A3%E3%81%9F%E3%82%89%E5%A2%97%E8%A8%AD%E3%81%A7%E3%81%8D%E3%82%8B%E3%81%8B%E3%81%A7%E6%B1%BA%E3%82%81%E3%82%8B",
        "aria-label": "8GBか16GBで迷ったら増設できるかで決める permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8GBか16GBで迷ったら増設できるかで決める`}</h2>
    <p>{`8GBで大抵快適であるが、16GBが欲しいタスクも存在し、また16GBにすることでデュアルチャネル化の恩恵も場合により受けられるというのがここまでの話である。`}</p>
    <p>{`16GBにするべきは分からないが、予算は抑えたいならば購入後にメモリを増やせるかを基準にすれば良い。`}</p>
    <p>{`これにはメモリの増設が自分でできそうか？と、増設自体が可能なパソコンか？という問いに答える必要がある。`}</p>
    <h3 {...{
      "id": "メモリの増設は簡単",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AE%E5%A2%97%E8%A8%AD%E3%81%AF%E7%B0%A1%E5%8D%98",
        "aria-label": "メモリの増設は簡単 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリの増設は簡単`}</h3>
    <Image {...props} name="memoryexpansion_s" alt="メモリの増設" mdxType="Image" />
    <p>{`メモリの増設方法をステップ毎に説明していく。`}</p>
    <p><em parentName="p">{`①ケースを開けて既存のメモリを取り出して規格・速度を確認する。`}</em></p>
    <p>{`パソコンケースの開け方は購入したパソコンの説明書に書いてあるはずである。デスクトップパソコンの場合はドライバーでケースを空けるか、手で取り外せるようになっているかいずれかである。`}</p>
    <p>{`また、ノートパソコンの場合はカバーをドライバーで取り外すと、メモリが挿し込まれていることを確認できる。`}</p>
    <p>{`取り出したら`}<em parentName="p">{`メモリ規格と速度`}</em>{`(後述)は何かを確認する。下記のようにラベルが貼ってあるのでそこで確認が可能である。`}</p>
    <Image {...props} name="ddr" alt="メモリの仕組み" mdxType="Image" />
    <p>{`DDR4が規格だが、PC4と書かれている場合もある。私のものだとサムスン製のメモリはPC4と書かれていた。`}</p>
    <p><em parentName="p">{`②同種の規格・速度のメモリを購入する`}</em></p>
    <p>{`Amazonなどで`}<a href="https://amzn.to/3gRCEcO" target="_blank" rel="nofollow noopener">{`DDR4 2666 8GB`}</a>{`などと検索すると、対象のメモリを見つけることができる。`}</p>
    <p>{`注意としてノートパソコン用はSO-DIMM、デスクトップ用はDIMMとサイズが異なる。ノート用であるならばSODIMMと検索に追加すれば良い。`}</p>
    <p>{`メモリ速度の2666は必ずしも合わせる必要はないが、デュアルチャネルで速度が遅い方が基準となってしまうため、できるだけ合わせた方が良い。`}</p>
    <p><em parentName="p">{`③空いているメモリスロットに購入したメモリを挿す`}</em></p>
    <p>{`メモリスロット数はノートやスリム型のデスクトップパソコンでは２つ、大型のデスクトップパソコンならば４つであるため、8GBのメモリを１枚挿した状態であればスロットは必ず空いている。`}</p>
    <p>{`空いているスロットに挿そう。４つスロットが備わっている場合は、デュアルチャネルの有効化のために`}<em parentName="p">{`同じ色のスロット`}</em>{`に挿す事が重要となる。`}</p>
    <p><em parentName="p">{`④パソコンを起動する`}</em></p>
    <p>{`最後にパソコンを起動しよう。これでメモリが増設されているはずである。`}<em parentName="p">{`Ctrl + Shift + Escキーを同時に押す`}</em>{`とタスクマネージャーが起動できるので、そこでメモリが増えた事を確認できる。`}</p>
    <Image {...props} name="task16gb" alt="タスクマネージャでメモリを確認" mdxType="Image" />
    <h3 {...{
      "id": "モバイルノートなど一部機種は増設できない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%83%8E%E3%83%BC%E3%83%88%E3%81%AA%E3%81%A9%E4%B8%80%E9%83%A8%E6%A9%9F%E7%A8%AE%E3%81%AF%E5%A2%97%E8%A8%AD%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84",
        "aria-label": "モバイルノートなど一部機種は増設できない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モバイルノートなど一部機種は増設できない`}</h3>
    <p>{`このようにメモリの増設は簡単に行えるため、必要になった時点で買い足すことができる。`}</p>
    <p>{`ところが`}<strong parentName="p">{`モバイルノートなどの機種では、狭いボディに部品を工夫して詰め込む構造となっており、メモリの増設ができないことが多い`}</strong>{`。`}</p>
    <p>{`デスクトップや、一般的なスタンダードノートなどは間違えなく増設が可能であるが、モバイルノートや、少し奇抜なデザインのパソコンは注意した方が良い。`}</p>
    <p>{`スペック表に増設不可と書かれることも多いため、購入時はネットで検索するなどしてメモリの増設が可能か調べておくと良い。`}</p>
    <p><strong parentName="p">{`増設不可が分かったならば、メモリ不足を将来解消する事ができないため、`}<em parentName="strong">{`不安ならば余裕をもって最初からメモリを十分に積んでおく必要がある`}</em></strong>{`だろう。`}</p>
    <h2 {...{
      "id": "メモリ関連のスペック・用語説明",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E9%96%A2%E9%80%A3%E3%81%AE%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%83%BB%E7%94%A8%E8%AA%9E%E8%AA%AC%E6%98%8E",
        "aria-label": "メモリ関連のスペック・用語説明 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリ関連のスペック・用語説明`}</h2>
    <p>{`最後にメモリの仕様を見ても良くわからないと思うので何となく分かる程度に説明しておく。`}</p>
    <p>{`下の例で、PC5、DDR5は規格の名前である。メモリ増設を行うときは規格が違えば認識してくれないため注意しなければならない。後ろの38400は伝送速度、この値が大きいほど高速と考えてよい。SDRAMはメモリの正式名称。より詳しく見ていこう。`}</p>
    <table border="1">
  <tbody>
    <tr><th rowSpan="2">メモリ</th><th>容量・規格</th><td>16GB PC5-38400<br />(DDR5 SDRAM, 8GBx2, デュアルチャネル, 最大 64GB)</td></tr>
    <tr><th>スロット数</th><td>4</td></tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "DDRダブルデータレート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#DDR%E3%83%80%E3%83%96%E3%83%AB%E3%83%87%E3%83%BC%E3%82%BF%E3%83%AC%E3%83%BC%E3%83%88",
        "aria-label": "DDRダブルデータレート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DDR(ダブルデータレート)`}</h3>
    <p>{`Double Data Rateの略、昔のメモリは一つの信号（クロック)でデータの書き込みと読み込みを同じタイミングで行う事ができなかった。`}</p>
    <p>{`例えるならば貨物列車で行きだけ荷物を持っていき、帰りは何も荷物を持たずに空のまま戻るといった具合である。`}</p>
    <p>{`そこで新しい規格として帰りも荷物を積んで帰るという規格がDDRである。同時に書き込み、読み込みができるレール数の違いによりDDR2、DDR3、DDR4、DDR5という規格が存在する。`}</p>
    <p>{`すなわち貨物列車をDDR2ならば2台、DDR3ならば各レーンを更に並列化させて4台、DDR4ならば8台、DDR5ならば更に並列させて16台同時に走らせるといった具合である。`}</p>
    <p>{`ただし並列化(レーンの数を増やすこと)させるごとに貨物列車のスピードが約半分になるようで（メモリ観点では命令を送るクロックの数が半分になる)、DDR4から5に上がったタイミングでは転送速度はほぼ変わらない。`}</p>
    <p>{`ただし、各スピードが半分になるという事は安定性が向上して、電力消費が少なくなるというメリットがある。`}</p>
    <h3 {...{
      "id": "PC5-38400",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#PC5-38400",
        "aria-label": "PC5 38400 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PC5-38400`}</h3>
    <p>{`PCはPersonal Computerの略ではなく、Pipeline Clockの略である。パイプライン式にデータ書き込み・読み取り信号を流して送り、5はDDRと同じ5番目の規格という意味である。`}</p>
    <p>{`38400は伝送速度(MegaByte,MB)である。PC5-38400はモジュール規格であるが、メモリチップの規格としてDDR5-4800(Mt/s)と表現されることもある。`}</p>
    <p>{`Mt/sはMega transfer per secondで1秒あたり4800Mbitの伝送量がある事を表している。これは1bitの場合で、データ入出力の個数が64個ある64bit対応（現在のパソコンは基本64bit)のメモリの場合には、そのそれぞれの1bitに相当する部分の伝送量が4800Mbit、バイトに直すと600MB(Mega Byte)であるため64bitで38400MB/s(=38.4GB/s)の伝送速度があることを示している。`}</p>
    <p>{`DDR5-4800とPC5-38400はチップの規格か、そのチップを内包したモジュールの規格を言っているかの違いであり、ユーザー観点での差異はない。`}<strong parentName="p">{`PC5-38400はDDR5-4800の8倍速いと勘違いしないように`}</strong>{`。`}</p>
    <p>{`デュアルチャネルの箇所でも述べたが、伝送速度、すなわち帯域幅が大きいほど動画のエンコード/デコード、Zipファイルの解凍などの処理が短時間で終わるようになる。`}</p>
    <h3 {...{
      "id": "SDRAM",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SDRAM",
        "aria-label": "SDRAM permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SDRAM`}</h3>
    <p>{`メモリの正式名称(Synchronous Dynamic Random Access Memory)、パソコン購入時に単にメモリと言うとこのSDRAMを指す。パソコンの電源を切るとSDRAMの中で電荷が失われ、記録が消される点でハードディスクやSSDと異なる。`}</p>
    <h3 {...{
      "id": "スロット数",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%AD%E3%83%83%E3%83%88%E6%95%B0",
        "aria-label": "スロット数 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`スロット数`}</h3>
    <Image {...props} name="memoryslot_s" alt="メモリスロット" mdxType="Image" />
    <p>{`メモリ挿し込み口の数、この数により増設が可能かが決まる。基本的にはデスクトップPCで４つ、ノートPCでは２つであるが、モニターと一体型のデスクトップPCや超小型デスクトップPCでは2枚である事が多い。`}</p>
    <p>{`メモリスロットが２つの場合、4GBのメモリを２枚搭載してしまったがためにスロットの空きが無くなり、16GBへの増設を断念しなければならないう失敗もある。`}</p>
    <p>{`すなわち`}<strong parentName="p">{`将来的に`}<em parentName="strong">{`増設を考えるならば空きスロット数には注意を払うべき`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "DIMM、-SO-DIMM",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#DIMM%E3%80%81-SO-DIMM",
        "aria-label": "DIMM、 SO DIMM permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DIMM、 SO-DIMM`}</h3>
    <p>{`DIMMはデスクトップ用のメモリで、SO-DIMMはノートパソコン用のメモリのことである。DIMMはdual in-line memory moduleの略でSO-DIMMのSOはsmalloutline、要するに小型のDIMMということである。`}</p>
    <p>{`このモジュールの種類が異なるパソコンにはメモリを増設する事ができない。SO-DIMMは一体型パソコンなどのスリムなデスクトップパソコンにも使われている。増設時には間違えないようにする必要がある。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      